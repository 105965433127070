// React
import { ReactElement, useEffect, useState } from 'react';
// Next
import { useTranslation } from 'next-i18next';
// Context
import { useUser } from '@utmb/contexts/AuthContext';
// Utils
import { getColorStyle } from '@utmb/utils/colors';
import { parseMarkdown } from '@utmb/utils/markdown';
// Design System
import { Font } from 'src/design-system/text/font';
import { Link } from 'src/design-system/link/link';
import { Pin } from '@utmb/design-system/pin/Pin';
import { Spacer } from 'src/design-system/spacer/Spacer';
import { Image } from '@utmb/design-system/image/Image';
// Interfaces
import { CloudinaryMediaView } from '@utmb/types/index';
import { ColorTypesEnum } from '@utmb/types/colors';
import SliceCreateYourAccountView from '@utmb/types/view/SliceCreateYourAccountView';
// Styles
import styles from './create-your-account.module.scss';

export const CreateYourAccount = (props: SliceCreateYourAccountView): ReactElement => {
    const { title, content } = props;

    const context = useUser();
    const { t } = useTranslation();

    const [isLogged, setIsLogged] = useState(false);

    useEffect(() => {
        setIsLogged(context.isAuthenticated ?? false);
    }, [context]);

    return (
        <>
            {!isLogged && (
                <div className={styles.container}>
                    <div className={styles.img_wrapper}>
                        <Pin.Cover xy={{ zIndex: -1 }}>
                            <Image
                                objectFit="contain"
                                layout="fill"
                                image={media as CloudinaryMediaView}
                                objectPosition="right bottom"
                                className={styles.background_img}
                            />
                        </Pin.Cover>
                    </div>
                    <div className={`container ${styles.full_height}`}>
                        <div className={`row ${styles.cya_row}`}>
                            <div className={`col-12 col-lg-6 ${styles.left_side}`}>
                                {title && (
                                    <Font.FuturaBold as="div" mobile="36" desktop="36" className={styles.title}>
                                        {title}
                                    </Font.FuturaBold>
                                )}
                                <Spacer.M />
                                {content && (
                                    <Font.FuturaBook as="div" mobile="14" desktop="16" className={styles.subtitle}>
                                        <div className="markdown" dangerouslySetInnerHTML={{ __html: parseMarkdown(content) }} />
                                    </Font.FuturaBook>
                                )}
                            </div>
                            <div className={`col-12 col-lg-6 ${styles.right_side}`}>
                                <Link.Button
                                    className={`myutmb-create-account ${styles.link}`}
                                    label={t('createYourAccount.buttonLabel')}
                                    href="/"
                                    onClick={(event) => {
                                        if (event?.preventDefault) event.preventDefault();
                                        context.login();
                                    }}
                                    color={getColorStyle(ColorTypesEnum.WS_PRIMARY)}
                                    bgcolor={getColorStyle(ColorTypesEnum.WS_SECONDARY)}
                                ></Link.Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

const media = {
    publicId: 'Common/Backgrounds/mydashboard-isometric-mobile',
    alt: 'MyUTMB Account',
    ratio: 1.5,
    type: 'image',
    width: 825,
    height: 954,
};
